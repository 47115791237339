<template>
  <div>
    <div class="cabecalho">
      <div class="cabecalho-content">
        <span>Placa: {{formData.veiculo.placa}}</span>
        <span style=" font-size: 21px">{{ formData.placa }}</span>
        <span>Token: {{formData.veiculo.token}}</span>
        <span style=" font-size: 21px">{{ formData.token }}</span>
      </div>
    </div>
    <div class="md-layout md-gutter md-alignment-center">
    <div class="md-layout-item md-size-55 md-medium-size-55 md-small-size-100">
      <md-card>
        <md-card-header>
          <md-card-header-text>
            <div class="md-title title">Destinando para pesagem</div>
          </md-card-header-text>
        </md-card-header>
        <md-card-content>
          <form @submit="handleSubmit">
            <DxForm
              ref="formRef"
              :form-data="formData"
              :items="itemsForm"
              label-location="top"
              :show-validation-summary="true"
            />
          </form>
          <div class="content-table">
            <div class="title-table">
              CTRS Prontas para Pesagem
            </div>
            <table>
            <thead>
            <tr>
              <th></th>
              <th>CTR</th>
              <th>Caçamba</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in grid" :key="row.id">
              <td><button @click="deleteRow(row.id)">X</button></td>
              <td>{{ row.id }}</td>
              <td>{{ row?.cacamba?.numeroIdentificacao }}</td>
            </tr>
            </tbody>
          </table>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
  </div>
</template>
<script setup>
import notify from 'devextreme/ui/notify'
import { DxForm } from 'devextreme-vue/form'
import CustomStore from 'devextreme/data/custom_store'
import { http } from '@/pluggables/http'
import { ref, onMounted } from 'vue'
import { confirm } from 'devextreme/ui/dialog'

const router = Promise.resolve(import('@/router')).then(module => module.default)
const ctrList = ref([])
const destinoFinalList = ref([])
const origemList = ref([])
const grid = ref()
const formRef = ref()

const formData = ref({
  ctr: null,
  destinoFinal: null,
  cacamba: null,
  produto: null,
  origem: null,
  veiculo: {
    id: null,
    placa: null,
    token: null,
    tipo: null,
    poliguindaste: null,
    idPerfil: null
  }
})
const itemsForm = [
  {
    dataField: 'ctr',
    label: { text: 'CTR:' },
    editorType: 'dxLookup',
    validationRules: [{ type: 'required', message: 'CTR é obrigatorio' }],
    editorOptions: {
      valueExpr: 'id',
      displayExpr: 'id',
      dropDownOptions: { hideOnOutsideClick: true },
      dataSource: new CustomStore({
        key: 'id',
        load: async (e) => {
          if (e.searchValue) {
            const filter = ctrList.value.filter(ctr => ctr.id === Number(e.searchValue))

            if (filter.length) return filter

            const input = {
              idCtr: e.searchValue,
              idTransportador: formData.value.veiculo.idPerfil,
              situacao: 'Retirada',
              placa: formData.value.veiculo.placa,
              token: formData.value.veiculo.token
            }
            const { data } = await http.post('/destinoRapido/searchCtr', input)
            ctrList.value = data
            return ctrList.value
          } else {
            const input = {
              situacao: 'Retirada',
              placa: formData.value.veiculo.placa,
              token: formData.value.veiculo.token
            }
            const { data } = await http.post('/destinoRapido/searchCtr', input)
            ctrList.value = data
            return ctrList.value
          }
        },
        byKey: key => ctrList.value.find(item => item.id === key)
      }),
      onValueChanged: (e) => {
        const ctrData = ctrList.value.find(item => item.id === e.value)
        formData.value.destinoFinal = ctrData?.destinoFinal?.id || ''
        formData.value.cacamba = ctrData?.cacamba?.numeroIdentificacao || ''
        formData.value.produto = ctrData?.residuoPredominante?.nome || ''
      }
    }
  },
  {
    dataField: 'destinoFinal',
    label: { text: 'Destino Final:' },
    editorType: 'dxLookup',
    validationRules: [{ type: 'required', message: 'Destino Final é obrigatorio' }],
    editorOptions: {
      valueExpr: 'id',
      displayExpr: 'nomeRazaoSocial',
      dropDownOptions: { hideOnOutsideClick: true },
      dataSource: new CustomStore({
        key: 'id',
        load: async (e) => {
          if (e.searchValue) {
            return destinoFinalList.value.filter(destinoFinal => destinoFinal.nomeRazaoSocial.includes(e.searchValue.toUpperCase()))
          }

          if (!!e.skip && e.skip !== 0) return

          const { data } = await http.get('/destinoRapido/destinoFinal')
          destinoFinalList.value = data
          return destinoFinalList.value
        },
        byKey: key => destinoFinalList.value.find(item => item.id === key)
      })
    }
  },
  {
    dataField: 'cacamba',
    label: { text: 'Caçamba:' },
    editorOptions: { readOnly: true }
  },
  {
    dataField: 'produto',
    label: { text: 'Produto:' },
    editorOptions: { readOnly: true }
  },
  {
    dataField: 'origem',
    label: { text: 'Origem:' },
    editorType: 'dxLookup',
    validationRules: [{ type: 'required', message: 'Origem é obrigatorio' }],
    editorOptions: {
      valueExpr: 'id',
      displayExpr: 'nome',
      searchExpr: ['nome'],
      dropDownOptions: { hideOnOutsideClick: true },
      dataSource: new CustomStore({
        key: 'id',
        load: async (e) => {
          if (e.searchValue) {
            const filter = origemList.value.filter(item => item.nome.toUpperCase().includes(e.searchValue.toUpperCase()))
            if (filter.length) return filter
          }
          origemList.value = (await http.get('/destinoRapido/localidade')).data
          origemList.value = origemList.value.map(item => ({ id: item.id, nome: `${item.codigo} - ${item.nome}` }))
          return origemList.value
        },
        byKey: key => key
      })
    }
  },
  {
    itemType: 'button',
    buttonOptions: { text: 'PESAR', useSubmitBehavior: true },
    horizontalAlignment: 'right',
    cssClass: 'btn-submit'
  },
  {
    template: '<div class="md-subhead" style="font-style: italic; font-weight: bold; margin-top: -20px; display: flex; justify-content: center;">Selecione uma CTR e uma Origem/Destino para pesar</div>'
  }
]
const verifyPlacaToken = async (placa, token) => {
  try {
    const router = await import('@/router')
    const { data } = await http.get(`/destinoRapido/findVeiculo/${placa}/${token}`)
    if (placa !== data.placa) {
      notify({
        type: 'negative',
        message: 'Nenhum veículo foi encontrado a partir desta placa e deste token'
      })
      await router.default.push('/destino-rapido')
    } else {
      formData.value.veiculo = data
    }
  } catch (err) {
    notify({
      type: 'negative',
      message: 'Não foi possível buscar o veículo, por favor, contate um administrador do sistema'
    })
  }
}
const verifyVeiculo = () => {
  if (formData.value.veiculo.tipo === 'Basculante' && grid.value.length >= 1) {
    throw new Error('Veículo Basculante só permite a pesagem de 1 CTR')
  }
  if (formData.value.veiculo.tipo === 'Poliguindaste' && formData.value.veiculo.poliguindaste === 'Simples' && grid.value.length >= 1) {
    throw new Error('Esse veículo só permite a pesagem de 1 CTR')
  }
  if (formData.value.veiculo.tipo === 'Poliguindaste' && formData.value.veiculo.poliguindaste === 'Duplo' && grid.value.length >= 2) {
    throw new Error('Esse veículo só permite a pesagem de 2 CTRs')
  }
  if (formData.value.veiculo.tipo === 'Poliguindaste' && formData.value.veiculo.poliguindaste === 'Triplo' && grid.value.length >= 3) {
    throw new Error('Esse veículo só permite a pesagem de 3 CTRs')
  }
}
const deleteRow = async (id) => {
  try {
    const result = await confirm('<i>Tem certeza que deseja remover esta CTR?</i>', 'Confirmar')
    if (!result) return
    await http.put(`/destinoRapido/deleteSolicitacaoTransporte/${id}`)
    const index = grid.value.findIndex(item => item.id === id)
    grid.value.splice(index, 1)
    formRef.value.instance.getEditor('ctr').option('dataSource', await itemsForm[0].editorOptions.dataSource.load())
    formRef.value.instance.getEditor('ctr').getDataSource().reload()
    notify({ message: 'CTR Removida com sucesso' }, 'success', 3000)
  } catch (err) {
    notify({ message: err }, 'error', 3000)
  }
}
const verifyGrid = (data) => {
  if (data.some(item => 'idResiduoPredominante' in item)) {
    const idResiduoPredominante = ctrList.value.find(i => i?.id === formData.value.ctr)?.residuoPredominante.id
    return data.length > 0 && !(grid.value.some(row => row.idResiduoPredominante === idResiduoPredominante))
  } else {
    const idResiduoPredominante = ctrList.value.find(i => i?.id === formData.value.ctr)?.residuoPredominante.id
    return data.length > 0 && !(grid.value.some(row => row.residuoPredominante.id === idResiduoPredominante))
  }
}
const handleSubmit = async (e) => {
  try {
    e.preventDefault()
    verifyVeiculo()

    if (verifyGrid(grid.value)) {
      const result = await confirm('<i>Você está tentando pesar 2 classes de materiais diferentes, será adotada a Classe Mista para todas as CTRs, deseja continuar?</i>', 'Confirmar')
      if (result) {
        const update = grid.value.filter(item => item.id !== formData.value.ctr).map(item => {
          return {
            id: item.id,
            idDestinoFinal: item.destinoFinal ? item.destinoFinal.id : item.idDestinoFinal,
            idResiduoPredominante: 5,
            idLocalidade: item.idLocalidade,
            placa: item.veiculo ? item.veiculo.placa : item.placa,
            token: item.veiculo ? item.veiculo.token : item.token,
            idVeiculo: item.veiculo ? item.veiculo.id : item.idVeiculo,
            situacao: item.situacao
          }
        })
        for (const item of update) {
          const { id, ...res } = item
          await http.put(`/destinoRapido/solicitacaoTransporte/${id}`, res)
        }
        const input = {
          idDestinoFinal: formData.value.destinoFinal,
          idResiduoPredominante: 5,
          idLocalidade: formData.value.origem,
          placa: formData.value.veiculo.placa,
          token: formData.value.veiculo.token,
          situacao: 'Em Pesagem',
          idVeiculo: formData.value.veiculo.id
        }
        await http.put(`/destinoRapido/solicitacaoTransporte/${formData.value.ctr}`, input)
        grid.value.push({
          id: formData.value.ctr,
          ...input,
          cacamba: {
            id: formData.value.cacamba,
            numeroIdentificacao: formData.value.cacamba
          }
        })
      } else {
        return
      }
    } else {
      const input = {
        idDestinoFinal: formData.value.destinoFinal,
        idResiduoPredominante: ctrList.value.find(item => item.id === formData.value.ctr).residuoPredominante.id,
        idLocalidade: formData.value.origem,
        placa: formData.value.veiculo.placa,
        token: formData.value.veiculo.token,
        situacao: 'Em Pesagem',
        idVeiculo: formData.value.veiculo.id
      }
      await http.put(`/destinoRapido/solicitacaoTransporte/${formData.value.ctr}`, input)
      grid.value.push({
        id: formData.value.ctr,
        ...input,
        cacamba: {
          id: formData.value.cacamba,
          numeroIdentificacao: formData.value.cacamba
        }
      })
    }
    const index = ctrList.value.indexOf(ctrList.value.find(i => i.id === formData.value.ctr))
    ctrList.value.splice(index, 1)
    formRef.value.instance.getEditor('ctr').option('dataSource', ctrList.value)
    formRef.value.instance.getEditor('ctr').getDataSource().reload()
    formRef.value.instance.resetValues()
    notify({ message: 'CTR Adiciona com sucesso' }, 'success', 3000)
  } catch (err) {
    notify({ message: err }, 'error', 3000)
  }
}
onMounted(async () => {
  const { params } = (await router).history.current
  await verifyPlacaToken(params.placa, params.token)
  const input = {
    situacao: 'Em Pesagem',
    placa: formData.value.veiculo.placa,
    token: formData.value.veiculo.token
  }
  const { data } = await http.post('/destinoRapido/searchCtr/', input)
  grid.value = data
  await itemsForm[1].editorOptions.dataSource.load()
})
</script>
<style lang="scss">
.cabecalho {
  min-height: 50px;
  width: 100%;
  background: #ff8402;
  display: flex;
  align-items: center
}
.cabecalho-content {
  padding: 0 12px;
  color: #ffffff;
}
.cabecalho-content > span:nth-child(odd) {
  font-size: 21px; font-weight: bold; padding-right:5px
}
.title {
  color: rgb(255, 132, 2);
}

.btn-submit > div{
  background-color: rgb(255, 132, 2);
  color: white;
  width: 100%;
  margin: 16px 0;
}
.btn-submit > div:hover{
  background-color: rgb(255, 132, 2);
  color: white;
}
.content-table {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-table {
  background: #ff8402;
  color: #ffffff;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
table {
  width: 100%;
  text-align: center;
}
table > thead > tr > th, table > tbody > tr > td {
  box-shadow: #000000 0px 0px 1px;
  padding: 8px 0;
}
table > tbody > tr:nth-child(even) {
  background: #f5f5f5;
}
table > tbody > tr > td > button {
  background-color: transparent;
  color: #f60606;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}
@media (max-width: 600px) {
  table {
    width: 100%;
  }
}
</style>
