<template>
  <div class="form-pessoa">
    <div class="form flex">
      <div class="field">
        <b>Tipo Pessoa*:</b>
        <dx-select-box
          :disabled="true"
          v-model="form.tipoPessoaStr"
          :items="[{ id: 'F', tipo: 'Pessoa Física' }, { id: 'J', tipo: 'Pessoa Jurídica' }]"
          value-expr="id"
          display-expr="tipo"
        />
      </div>
    </div>

    <!-- Dados da pessoa empresa -->
    <div v-if="form.tipoPessoaStr === 'J'">
      <fieldset>
        <legend>Dados da empresa</legend>
        <div class="form flex">
          <div class="flex-col-9">
            <b>CNPJ*</b>
            <dx-text-box
              :disabled="!isAdministrador()"
              v-model="form.cnpj"
              mask="00.000.000/0000-00"
              :validation-status="formValid.cnpj"
            />
          </div>
          <div class="flex-col-9">
            <b>{{ form.tipoPessoaStr === 'F' ? 'Nome' : 'Razão Social' }}*</b>
            <dx-text-box
              v-model="form.nomeRazaoSocial"
              :validation-status="formValid.nomeRazaoSocial"
            />
          </div>
        </div>
        <div class="form flex" v-if="!editingPjFoundAutoCadastro">
          <form-usuario-update
            ref="formularioPessoaPj"
            :usuario-to-edit="form.pessoaJuridica"
            :ocultar-campos="ocultarCamposAutoCadatroPj"
          />
        </div>
      </fieldset>
    </div>

    <!-- Dados da pessoa física ou do usuário responsável -->
    <div v-if="form.tipoPessoaStr">
      <fieldset>
        <legend>{{ form.tipoPessoaStr === 'F' ? 'Dados de cadastro' : 'Responsáveis' }}</legend>
        <div class="form flex" v-if="form.tipoPessoaStr === 'F'">
          <form-usuario-update
            ref="formularioUsuarioPessoaFisica"
            :usuario-to-edit="form.usuarioPessoaFisica"
            :ocultar-campos="autoCadastro
            ? ['rg', 'responsavelAssinaturaContrato' , 'tipoResponsavel', 'profissao', 'cargo', ...($utils.isFinalId(form.id) ? ['confirmarEmail'] : [])]
            : ['rg', 'senha', 'responsavelAssinaturaContrato' , 'tipoResponsavel', 'profissao', 'cargo', ...($utils.isFinalId(form.id) ? ['confirmarEmail'] : [])]"
          />
        </div>
        <div v-else-if="!editingPjFoundAutoCadastro">
          <dx-button type="default" @click="openAdicionarResponsavel">Adicionar Responsável</dx-button>
          <dx-data-grid
            :data-source="responsaveisComp"
            :columns="responsaveisColumns"
            value-expr="cpf"
            display-expr="nome"
            :allow-deleting="true"
            :confirm-delete="true"
          />
        </div>
      </fieldset>
    </div>

    <!-- Perfis e Usuários -->
    <div v-if="form.tipoPessoaStr" class="form">
      <!-- PERFIL-->
      <fieldset>
        <legend>Perfis</legend>
        <dx-button
          type="default"
          :disabled="disabledButtonAdicionarPerfilComp"
          @click="addPerfil">Adicionar Perfil*
        </dx-button>
        <div class="boxes-perfil">
          <div :key="i" v-for="(perfil, i) in perfisListComp" class="box-perfil">
            <div class="box-acoes">
              <div v-show="perfisListComp.length > 1" @click="removePerfil(perfil)">
                <md-icon class="mdi mdi-close-circle remover-perfil" title="Remover Perfil"/>
              </div>
            </div>
            <div class="box-form">
              <div class="field">
                <b>Tipo de Perfil*:</b>
                <dx-select-box
                  v-model="perfil.idTipoPerfil"
                  :items="getTipoPerfilDisponivelList(perfil)"
                  value-expr="id"
                  display-expr="descricao"
                  placeholder="Selecione um tipo de perfil"
                  :validation-status="formValid.perfis[i] || 'valid'"
                />
              </div>

              <div class="field" v-if="isTipoPerfil(perfil, 'Destino Final')">
                <b>Tipo de destino final:</b>
                <dx-select-box
                  v-model="perfil.tipoDestinoFinal"
                  :items="['Aterro', 'ATT', 'PEV', 'Recicladora']"
                  placeholder="Selecione um tipo de destino final"
                />
              </div>

              <div class="field" v-if="isTipoPerfil(perfil, 'Destino Final')">
                <b>Capacidade máxima em m<sup>3</sup> suportada:</b>
                <dx-number-box v-model="perfil.capacidadeMaximaSuportada"/>
              </div>

              <div class="field" v-if="isTipoPerfil(perfil, 'Secretaria') || isTipoPerfil(perfil, 'Administrador')">
                <b>Matrícula</b>
                <dx-text-box v-model="perfil.matricula" :maxLength="30" />
              </div>

              <div class="field" v-if="isTipoPerfil(perfil, 'Fiscalização')">
                <b>Órgão</b>
                <dx-select-box
                  :data-source="[
                    { text: 'AGEFIS', type: 'AGEFIS' },
                    { text: 'IBRAM', type: 'IBRAM' },
                    { text: 'SEMA', type: 'SEMA' }
                     ]"
                  display-expr="text"
                  value-expr="type"
                  v-model="perfil.orgao"
                />
                <b>Matrícula</b>
                <dx-text-box v-model="perfil.matricula" :maxLength="30" />
              </div>

              <!--  Documentos do Transportador -->
              <div class="form flex" v-if="isTipoPerfil(perfil, 'Transportador')">
                <div class="documents">
                  <div class="flex-col-12">
                    <h3>Forma de cobrança</h3>
                    <dx-select-box
                      width="280"
                      v-if="perfil && perfil._status === 'insert' ? true : calculateDataAlteracaoFormaCobranca(perfil.dataAlteracaoFormaCobranca).isValid"
                      v-model="perfil.formaCobranca"
                      :items="[{ id: 0, tipo: 'Cobrança por peso' }, { id: 1, tipo: 'Cobrança por caçamba' }]"
                      value-expr="id"
                      display-expr="tipo"
                      placeholder="Seleciona uma forma de cobrança"
                    />
                    <span v-if="perfil && perfil._status !== 'insert'"><b>{{ calculateDataAlteracaoFormaCobranca(perfil.dataAlteracaoFormaCobranca).message }}</b></span>
                    <h3>Documentos</h3>
                  </div>
                  <div class="flex-col-12">
                    <div class="fields-separator" v-for="(index,value) in documentsEnum[form.tipoPessoaStr]" :key="index">
                      <DxTooltip
                        :ref="`tooltip-${value}`"
                        :hide-on-outside-click="false"
                        :target="`#downloadAttachment-${value}`"
                      >
                        <span v-if="perfil && perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]]">
                          Baixar documento "{{perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]].name}}"
                        </span>
                      </DxTooltip>
                      <DxTooltip
                        v-if="!perfil._arquivos[value]"
                        :ref="`tooltip-uploader-${value}`"
                        :hide-on-outside-click="false"
                        :target="`#modelUploaderButton-${value}`"
                      >
                        <span v-if="perfil && perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]]">
                          Substituir anexo
                        </span>
                        <span v-else>
                          Adicionar documento
                        </span>
                      </DxTooltip>
                      <span>{{documentsEnum[form.tipoPessoaStr][value]}}</span>
                      <div style="display: flex">
                        <model-file-uploader
                          :button-label="perfil._arquivos[value] ? 'Substituir' : perfil && perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]] ? 'Substituir' : 'Adicionar'"
                          :button-id="`modelUploaderButton-${value}`"
                          :show-remove-button="false"
                          :icon="perfil && perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]] ? 'mdi mdi-swap-horizontal' : 'mdi mdi-upload'"
                          drag-and-drop-label=""
                          v-model="perfil._arquivos[value]"
                          accept=".pdf, image/*"
                          entity="Arquivo"
                          @mouseenter="toggleUploader(value)"
                          @mouseleave="toggleUploader(value)"
                        >
                          <template #customButtons>
                            <div
                              @mouseenter="toggleDefault(value)"
                              @mouseleave="toggleDefault(value)"
                              style="place-self:center"
                              v-if="perfil && perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]]">
                              <dx-button
                                :id="`downloadAttachment-${value}`"
                                type="default"
                                icon="mdi mdi-download"
                                @click="downloadAnexo('Arquivo', perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]].id, 'anexo', perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]].name)"
                              />
                              <!--                          <span style="place-self: center; font-weight: bold; margin-left: 10px"> {{ perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]].name }}</span>-->
                            </div>
                          </template>
                        </model-file-uploader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form flex" v-if="mostrarAnexoLicAmbientalComp(perfil)">
                <div class="flex-col-12">
                  <model-file-uploader
                    v-model="perfil._arquivos.licenciamentoAmbientalRcc"
                    accept=".pdf"
                    entity="Arquivo"
                    :label="'Anexar Licenciamento Ambiental'"/>
                  <div v-if="perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados.licenciamentoAmbientalRcc && perfil._arquivosIniciaisMetaDados.licenciamentoAmbientalRcc.id" style="display: inline-flex">
                    <dx-button
                      type="info"
                      text="Baixar Anexo"
                      @click="downloadAnexo('Arquivo', perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados.licenciamentoAmbientalRcc && perfil._arquivosIniciaisMetaDados.licenciamentoAmbientalRcc.id, 'anexo', 'Certificado_de_licenciamento_de_RCC.pdf')"
                    />
                    <span style="place-self: center; font-weight: bold; margin-left: 10px">{{ perfil._arquivosIniciaisMetaDados.licenciamentoAmbientalRcc.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <!-- USUARIOS-->
      <fieldset v-if="form.tipoPessoaStr === 'J'">
        <legend>Usuários</legend>
        <div v-if="email && login">
          <!--  -->
          <form-usuario-update
            ref="formPopupUsuarioAuto"
            :usuario-to-edit="popupAddUsuario.usuarioToEdit"
            :perfis-selecionaveis="getPerfisSelecionaveis()"
            :ocultar-campos="['rg', 'tipoResponsavel', 'responsavelAssinaturaContrato', 'profissao', 'cargo']"
          />
          <div v-if="!(perfisListComp.length >= 1 && perfisListComp[0].idTipoPerfil)"
               style="font-style: italic; font-size: 0.9em"
          ></div>
        </div>
        <div v-else>
          <dx-button type="default"
                     :disabled="!(perfisListComp.length >= 1 && perfisListComp[0].idTipoPerfil)"
                     @click="popupAddUsuario.show = true; popupAddUsuario.action = 'add'">Adicionar Usuário
          </dx-button>

          <div v-if="!(perfisListComp.length >= 1 && perfisListComp[0].idTipoPerfil)"
               style="font-style: italic; font-size: 0.9em"
          >
            Para adicionar um usuário, adicione um perfil antes
          </div>

          <div v-if="form.usuariosVinculadoPj.length">
            <dx-data-grid
              :data-source="usuariosVinculadoPjComp"
              :columns="usuarioGridData.columns"
              value-expr="cpf"
              display-expr="nome"
              :allow-deleting="true"
              :confirm-delete="true"
            />
          </div>
        </div>

      </fieldset>
    </div>

    <div class="form-actions">
      <dx-button type="success" @click="salvar">Salvar</dx-button>
    </div>

    <dx-popup
      title="Formulário adicionar responsável pela empresa"
      :height="500"
      :show-title="true"
      :dragEnabled="false"
      :visible.sync="popupResponsavel.show"
      @hidden="fecharPopupResponsavel"
    >
      <template v-slot:content>
        <dx-scroll-view>
          <form-usuario-update
            ref="formPopupResponsavel"
            :usuario-to-edit="popupResponsavel.responsavelToEdit"
            :ocultar-campos="['senha', 'confirmarEmail']"
          />
          <dx-button type="success" @click="salvarPopupResponsavel">Salvar</dx-button>
        </dx-scroll-view>
      </template>
    </dx-popup>

    <dx-popup
      title="Formulário adicionar Usuário"
      :height="500"
      :show-title="true"
      :dragEnabled="false"
      :visible.sync="popupAddUsuario.show"
      @hidden="fecharPopupUsuario"
    >
      <template v-slot:content>
        <dx-scroll-view>
          <form-usuario-update
            ref="formPopupUsuario"
            :usuario-to-edit="popupAddUsuario.usuarioToEdit"
            :popup-action="popupAddUsuario.action"
            :perfis-selecionaveis="getPerfisSelecionaveis()"
            :ocultar-campos="['senha', 'confirmarEmail', 'rg', 'tipoResponsavel', 'responsavelAssinaturaContrato', 'profissao', 'cargo']"
          />
          <dx-button type="success" @click="salvarPopupUsuario">Salvar</dx-button>
        </dx-scroll-view>
      </template>
    </dx-popup>
  </div>
</template>

<style lang="scss">
.fields-separator {
  padding: 10px;
  border-bottom: 0.1px solid lightgrey;
}
.form-pessoa {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .form-actions {
    margin: 10px 0;
  }

  .form {
    width: 100%;

    > div {
      margin: 0 10px 10px 0;
    }

    .field {
      display: flex;
      flex-direction: column;
    }
  }
  .boxes-perfil {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;

    .box-perfil {
      display: flex;
      flex-direction: column;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      margin-right: 20px;
      position: relative;

      .box-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 10px;

        > * {
          margin-right: 10px;
        }
      }

      .box-acoes {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 3px;
        right: -10px;

        > * {
          cursor: pointer;
        }

        .remover-perfil {
          color: #069;
          background: #fff;
        }
      }
    }
  }
}
</style>

<script>
import mixinCrud from '@/pages/Cruds/mixin-crud'
import { DxButton, DxDataGrid, DxNumberBox, DxPopup, DxScrollView, DxSelectBox, DxTextBox } from 'devextreme-vue'
import ModelFileUploader from '@/components/Form/ModelFileUploader'
import FormUsuarioUpdate from '@/pages/Cruds/Pessoa/Form/FormUsuarioUpdate.vue'
import config from 'config'
import moment from 'moment'
import { http } from '@/pluggables/http'
import { documentosEnum } from '@/pages/Cruds/Pessoa/documentosEnum'
import { DxTooltip } from 'devextreme-vue/tooltip'
import notify from 'devextreme/ui/notify'
import storeHelper from '@/helper/storeHelper'
import { mapGetters } from 'vuex'
import { confirm } from 'devextreme/ui/dialog'

const baseUrl = config.baseUrl
export const messageRequiredDefault = (nomeCampo, mensagemOpcional = '') => `O preenchimento do campo: ${nomeCampo} é obrigatório. ${mensagemOpcional}`

export default {
  name: 'pessoa-form-update',
  props: {
    autoCadastro: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: [Number, String]
    },
    name: {
      type: String,
      default: null
    },
    email: {
      type: String,
      default: null
    },
    password: {
      type: String,
      default: null
    },
    idAuth: {
      type: String,
      default: null
    },
    login: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    },
    action: {
      type: String
    }
  },
  components: {
    FormUsuarioUpdate,
    DxButton,
    DxPopup,
    DxSelectBox,
    DxTextBox,
    DxNumberBox,
    DxDataGrid,
    DxScrollView,
    ModelFileUploader,
    DxTooltip
  },
  mixins: [mixinCrud],
  data () {
    return {
      oldPessoaPerfis: [],
      entity: 'Usuario',
      formInitialized: false,
      editingPjFoundAutoCadastro: false,
      closeModalClearAll: {
        value: true
      },
      events: {
        save: 'save'
      },
      formValid: {
        cnpj: 'valid',
        nomeRazaoSocial: 'valid',
        perfis: []
      },
      usuarioDadosIniciais: null,
      form: {
        responsaveis: [],
        perfis: [],
        usuariosVinculadoPj: [], // Array<Object> contém lista de usuários vinculados ao Pessoa Jurídica
        tipoPessoaStr: null, // enum(F, J)

        pessoaJuridica: undefined, // Contém as informações de pessoa jurídica
        usuarioPessoaFisica: undefined // Objeto que representa o usuário representante desta pessoa
      },
      dynamicValues: {
        tipoPerfilList: []
      },
      popupResponsavel: {
        show: false,
        responsavelToEdit: undefined
      },
      popupAddUsuario: {
        show: false,
        action: '',
        usuarioToEdit: undefined
      },
      usuarioGridData: {
        columns: [
          {
            dataField: 'cpf',
            caption: 'CPF'
          },
          {
            dataField: 'nome',
            caption: 'Nome'
          },
          {
            dataField: 'email',
            caption: 'Email'
          },
          {
            type: 'buttons',
            width: 100,
            showInColumnChooser: false,
            buttons: [
              {
                hint: 'Remover Usuário',
                icon: 'trash',
                visible: true,
                onClick: ({ row }) => {
                  const result = confirm('Tem certeza que deseja excluir este usuário?', 'Atenção!')
                  result.then(async (dialogResult) => {
                    if (dialogResult) {
                      await this.removerUsuario(row.data)
                    }
                  })
                }
              },
              {
                hint: 'Editar Usuário',
                icon: 'edit',
                visible: true,
                onClick: ({ row }) => {
                  this.popupAddUsuario = {
                    show: true,
                    action: 'edit',
                    usuarioToEdit: row.data
                  }
                  this.$nextTick(() => {
                    if (this.$refs.formPopupUsuario) {
                      this.$refs.formPopupUsuario.startOrReset()
                    }
                  })
                }
              }
            ]
          }
        ]
      },
      responsaveisColumns: [
        {
          dataField: 'nome',
          caption: 'Nome do responsável'
        },
        {
          dataField: 'tipoResponsavel',
          caption: 'Exerce esta função'
        },
        {
          dataField: 'cargo',
          caption: 'Cargo'
        },
        {
          dataField: 'profissao',
          caption: 'Profissão'
        },
        {
          dataField: 'email',
          caption: 'Email'
        },
        {
          type: 'buttons',
          width: 100,
          showInColumnChooser: false,
          buttons: [
            {
              hint: 'Remover Responsável',
              icon: 'trash',
              visible: true,
              onClick: ({ row }) => {
                const result = confirm('Tem certeza que deseja excluir este responsável?', 'Atenção!')
                result.then(async (dialogResult) => {
                  if (dialogResult) {
                    await this.removerResponsavel(row.data)
                  }
                })
              }
            },
            {
              hint: 'Editar Responsável',
              icon: 'edit',
              visible: true,
              onClick: ({ row }) => {
                this.popupResponsavel = {
                  show: true,
                  responsavelToEdit: row.data
                }
                this.$nextTick(() => {
                  if (this.$refs.formPopupResponsavel) {
                    this.$refs.formPopupResponsavel.startOrReset()
                  }
                })
              }
            }
          ]
        }
      ],
      // hideAuto: false,
      ocultarCamposAutoCadatroPj: ['rg', 'cpf', 'nome', 'cargo', 'profissao', 'tipoResponsavel', 'responsavelAssinaturaContrato', 'senha', 'confirmarEmail'],
      documentsEnum: documentosEnum
    }
  },
  watch: {
    'form.usuarioPessoaFisica': {
      deep: true,
      handler (data) {
        if (this.$refs.formularioUsuarioPessoaFisica) this.$refs.formularioUsuarioPessoaFisica.form = data
      }
    },
    'closeModalClearAll.value' (value) {
      this.form = {
        ...this.form,
        usuarioPessoaFisica: undefined,
        pessoaJuridica: undefined,
        usuariosVinculadoPj: [],
        usuarios: [],
        tipoPessoaStr: null,
        responsaveis: [],
        perfis: [],
        nomeRazaoSocial: null
      }
      this.ocultarCamposAutoCadatroPj = ['cpf', 'nome',
        'cargo', 'profissao', 'tipoResponsavel',
        'responsavelAssinaturaContrato', 'senha',
        'confirmarEmail']
    },
    modal (status) {
      if (this.form.tipoPessoaStr === 'J') {
        this.form.pessoaJuridica = {
          ...this.form.pessoaJuridica,
          email: this.email,
          nome: this.name,
          idAuth: this.idAuth,
          login: this.login
        }
        this.popupAddUsuario.usuarioToEdit = {
          ...this.popupAddUsuario.usuarioToEdit,
          email: this.email,
          confirmarEmail: this.email,
          nome: this.name,
          senha: this.password,
          confirmarSenha: this.password,
          idAuth: this.idAuth,
          login: this.login
        }
        this.form.nomeRazaoSocial = this.name
      } else {
        this.form.usuarioPessoaFisica = {
          ...this.form.usuarioPessoaFisica,
          email: this.email,
          confirmarEmail: this.email,
          nome: this.name,
          senha: this.password,
          confirmarSenha: this.password,
          idAuth: this.idAuth,
          login: this.login
        }
      }
    },
    form: {
      deep: true,
      handler (form) {
        this.formValid.nomeRazaoSocial = typeof form.nomeRazaoSocial === 'string' && !form.nomeRazaoSocial.length ? 'invalid' : 'valid'
      }
    },
    perfisListComp (perfis) {
      this.formValid.perfis = perfis.map((p) => !p.idTipoPerfil ? 'invalid' : 'valid')
    },
    'form.cnpj': {
      async handler (cnpj = '', oldValue) {
        if (this.form.tipoPessoaStr === 'J' && cnpj.length === 14 && oldValue) {
          if (!this.$utils.validateCnpj(cnpj)) {
            this.formValid.cnpj = 'invalid'
            return this.$utils.notifyError(new CustomError('CNPJ inválido'))
          } else {
            this.formValid.cnpj = 'valid'
            this.setLoading(true)
            // const where = this.id ? { id: this.id } : { cpfCnpj: cnpj }
            const pessoa = await this.cpfCnpjExists({ value: cnpj })
            if (!pessoa) notify('Usuário já cadastrado', 'error')
            this.setLoading(false)
          }
        }
      }
    },
    'form.tipoPessoaStr': {
      async handler (tipoPessoaStr) {
        if (this.form.tipoPessoaStr === 'J') {
          this.popupAddUsuario.usuarioToEdit = {
            ...this.popupAddUsuario.usuarioToEdit,
            email: this.email,
            confirmarEmail: this.email,
            nome: this.name,
            senha: this.password,
            confirmarSenha: this.password,
            idAuth: this.idAuth,
            login: this.login
          }
          this.form = {
            ...this.form,
            email: this.email ? this.email : this.form.email,
            pessoaJuridica: {
              ...(this.form.pessoaJuridica || {}),
              email: this.email ? this.email : this.form.email
            }
          }
        }

        if (this.formInitialized) {
          this.form.perfis = []
          this.form.usuariosVinculadoPj = []
        }

        let where
        if (this.isAdministrador()) {
          where = tipoPessoaStr === 'F' ? {
            or: [
              { tipo: tipoPessoaStr },
              { id: { in: [3, 4, 5, 6, 10] } }
            ]
          } : {
            or: [
              { tipo: tipoPessoaStr },
              { id: { in: [10] } }
            ]
          }
        } else {
          where = tipoPessoaStr === 'F' ? {
            or: [
              { tipo: tipoPessoaStr },
              { id: { in: [3, 4, 5, 6] } }
            ]
          } : {
            tipo: tipoPessoaStr
          }
        }
        this.putDynamicValue('tipoPerfilList', await this.find({
          entity: 'TipoPerfil',
          rowsOnly: true,
          params: {
            where
          }
        }))

        await this.$nextTick()

        if (this.$refs.formularioUsuarioPessoaFisica) {
          if (this._unwatcherUsuarioPf) {
            this._unwatcherUsuarioPf()
            this._unwatcherUsuarioPf = null
          }
          this._unwatcherUsuarioPf = this.$refs.formularioUsuarioPessoaFisica.$watch('form.cpf', async (cpf) => {
            if (this.form.tipoPessoaStr === 'F' && cpf.length === 11) {
              if (this.$refs.formularioUsuarioPessoaFisica.formValid.cpf === 'valid') {
                this.setLoading(true)
                const pessoa = await this.cpfCnpjExists({ value: cpf })
                if (!pessoa) notify('Usuário já cadastrado', 'error')
                this.setLoading(false)
              } else {
                notify('CPF Inválido', 'error')
              }
            }
          })
        }
        // traz o campo de perfil ja aberto quando esta inserindo.
        if (!this.id) {
          this.form.perfis.push({
            _status: 'insert',
            _arquivos: {}
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters('Login', ['isLoggedIn']),
    perfisListComp () {
      return this.form.perfis.filter(({ _status }) => _status !== 'remove')
    },
    responsaveisComp () {
      return this.form.responsaveis.filter(({ _status }) => _status !== 'remove')
    },
    usuariosVinculadoPjComp () {
      return this.form.usuariosVinculadoPj.filter(({ _status }) => _status !== 'remove')
    },
    tipoPerfilDisponivelListComp () {
      return this.dynamicValues.tipoPerfilList.filter((tipoPerfil) => {
        return !this.perfisListComp.find(({ idTipoPerfil }) => tipoPerfil.id === idTipoPerfil)
      })
    },
    disabledButtonAdicionarPerfilComp () {
      return (this.form.tipoPessoaStr === 'J' && (!this.tipoPerfilDisponivelListComp.length || !!this.perfisListComp.find(({ idTipoPerfil }) => !idTipoPerfil)))
    }
  },
  async mounted () {
    await this.buildInitialValues()
    setTimeout(() => {
      this.formInitialized = true
    }, 1000)
  },
  methods: {
    getModifiedValues: (oldValues, newValues) => {
      const modifiedValues = {}

      for (const key in oldValues) {
        // eslint-disable-next-line no-prototype-builtins
        if (oldValues.hasOwnProperty(key) && newValues.hasOwnProperty(key)) {
          if (oldValues[key] !== newValues[key] && newValues[key] !== null) {
            modifiedValues[key] = newValues[key]
          }
        }
      }

      return modifiedValues
    },
    isAdministrador () {
      return this.isLoggedIn ? storeHelper.isAdministrador() : false
    },
    openAdicionarResponsavel () {
      this.popupResponsavel = {
        show: true
      }
      this.$nextTick(() => {
        if (this.$refs.formPopupResponsavel) {
          this.$refs.formPopupResponsavel.$data.started = true
          this.$refs.formPopupResponsavel.startOrReset()
        }
      })
    },
    async buildInitialValues (id = this.id) {
      if (id) {
        try {
          this.setLoading(true)
          const pessoa = (await this.find({
            rowsOnly: true,
            entity: 'Pessoa',
            params: {
              where: { id },
              include: JSON.stringify([
                {
                  model: 'Municipio',
                  as: 'municipio'
                },
                {
                  required: false,
                  model: 'Usuario',
                  as: 'usuarios',
                  where: {
                    excluido: 'N'
                  },
                  include: [
                    {
                      model: 'Perfil',
                      as: 'perfis'
                    },
                    {
                      model: 'Municipio',
                      as: 'municipio'
                    }
                  ]
                },
                {
                  model: 'Perfil',
                  as: 'perfis',
                  include: [
                    {
                      model: 'Arquivo',
                      as: 'arquivos',
                      attributes: ['id', 'descricao', 'nomeArquivo'],
                      include: [
                        {
                          required: true,
                          model: 'TipoArquivo',
                          as: 'tipoArquivo'
                        }
                      ]
                    }
                  ]
                },
                {
                  model: 'Responsavel',
                  as: 'responsaveis',
                  include: [
                    {
                      model: 'Municipio',
                      as: 'municipio'
                    }
                  ]
                }
              ])
            }
          }))[0]
          this.oldPessoaPerfis = pessoa.perfis

          if (pessoa) {
            const normalizePessoaEUsuario = (data = {}) => {
              return {
                ...data,
                numero: data.numero !== undefined ? String(data.numero) : null
              }
            }
            this.form = {
              ...normalizePessoaEUsuario(pessoa), // todos os dados de PF ou PJ na raiz do form
              ...(pessoa.tipoPessoa === 'J' ? {
                cnpj: pessoa.cpfCnpj,
                pessoaJuridica: {
                  ...normalizePessoaEUsuario(pessoa),
                  cnpj: pessoa.cpfCnpj
                }
              } : {}),
              ...(pessoa.tipoPessoa === 'F' ? {
                usuarioPessoaFisica: { ...normalizePessoaEUsuario(pessoa), cpf: pessoa.cpfCnpj, nome: pessoa.nomeRazaoSocial || pessoa.nome, id: pessoa.id },
                nome: pessoa.nome,
                nomeRazaoSocial: pessoa.nome,
                celular: pessoa.celular,
                telefone: pessoa.telefone,
                email: pessoa.email,
                confirmarEmail: pessoa.confirmarEmail,
                senha: pessoa.senha,
                cep: pessoa.cep,
                uf: pessoa.uf,
                codIbge: pessoa.codIbge,
                bairro: pessoa.bairro,
                endereco: pessoa.endereco,
                complemento: pessoa.complemento,
                numero: pessoa.numero
              } : {}),
              perfis: pessoa.perfis.map((perfil) => {
                const _arquivosIniciaisMetaDados = {}
                if (perfil.arquivos) {
                  const arquivos = perfil.arquivos.filter((arquivo) => !!(arquivo.id))
                  for (const arquivo of arquivos) {
                    _arquivosIniciaisMetaDados[arquivo.tipoArquivo.descricao] = {
                      id: arquivo.id,
                      fileName: arquivo.tipoArquivo.descricao,
                      name: arquivo.nomeArquivo,
                      contentType: arquivo.contentType
                    }
                  }
                }
                return {
                  ...perfil,
                  ...(perfil.capacidadeMaximaSuportada ? { capacidadeMaximaSuportada: Number(perfil.capacidadeMaximaSuportada) } : {}),
                  _status: this.$utils.isFinalId(perfil.id) && perfil._status === 'insert' ? 'update' : perfil._status,
                  _arquivosIniciaisMetaDados,
                  _arquivos: {}
                }
              }), //
              usuariosVinculadoPj: pessoa.tipoPessoa === 'J' ? pessoa.usuarios.map((user) => {
                const usuario = normalizePessoaEUsuario(user)
                return {
                  ...usuario,
                  _status: 'update',
                  idsTipoDePerfil: usuario.perfis.map(({ idTipoPerfil }) => idTipoPerfil)
                }
              }) : [],
              tipoPessoaStr: pessoa.tipoPessoa
            }
            if (pessoa.tipoPessoa === 'J' && this.autoCadastro) {
              this.editingPjFoundAutoCadastro = true
              // this.hideAuto = true
            }
          }
          if (this.form.pessoaJuridica) this.form.pessoaJuridica.email = {}
        } catch (error) {
          this.$utils.notifyError(error)
        } finally {
          this.setLoading(false)
        }
      }
    },
    toFormData (payload = {}) {
      const formData = new FormData()
      for (const key in payload) {
        const value = payload[key]
        if (typeof value !== 'undefined') {
          if (value instanceof File) {
            formData.append(key, value)
          } else if (Object.prototype.toString.call(value) === '[object Object]') {
            for (const prop in value) {
              const nestedValue = value[prop]
              if (typeof nestedValue !== 'undefined') {
                formData.append(`${prop}`, nestedValue)
              }
            }
          } else {
            formData.append(key, String(value))
          }
        }
      }
      return formData
    },
    async perfilSalvar () {
      for (const perfil of this.form.perfis) {
        if (perfil._status === 'insert' && !perfil.id) {
          // Se tipo perfil for Secretaria ou Fiscalização
          const isSecretariaOrFiscalizacaoOrAdministrador = perfil.idTipoPerfil === 4 || perfil.idTipoPerfil === 5 || perfil.idTipoPerfil === 10
          if (!isSecretariaOrFiscalizacaoOrAdministrador) delete perfil.matricula
          // Se tipo perfil for Transportador do tipo Pessoa Jurídica ou Transportador do tipo Pessoa Física
          const isTransportador = perfil.idTipoPerfil === 2 || perfil.idTipoPerfil === 9
          if (!isTransportador) {
            delete perfil.formaCobranca
          } else {
            perfil.dataAlteracaoFormaCobranca = new Date()
          }
          const formData = this.toFormData(perfil)
          formData.append('idPessoa', this.id)
          const res = await this.$http.post(this.$http.normalizeUrl('/perfil/add/'), formData)
          if (res.data) {
            perfil.id = res.data.perfil.id
            perfil._status = 'update'
            this.oldPessoaPerfis.push({ ...res.data.perfil })
          }
          if (this.form.tipoPessoaStr === 'F') {
            await this.$http.put(this.$http.normalizeUrl(`/usuario/update/${this.form.usuarios[0].id}`), {
              perfis: [{
                id: res.data.perfil.id,
                action: 'create'
              }]
            })
          }
        }
      }
    },
    async perfilUpdate () {
      for (const perfil of this.form.perfis) {
        if (!perfil._status) {
          perfil._status = 'update'
        }
        if (perfil._status === 'update' && perfil.id) {
          const isSecretariaOrFiscalizacaoOrAdministrador = [4, 5, 10].includes(perfil.idTipoPerfil)
          const isTransportador = [2, 9].includes(perfil.idTipoPerfil)

          if (!isSecretariaOrFiscalizacaoOrAdministrador) delete perfil.matricula

          if (!isTransportador) delete perfil.formaCobranca
          else {
            perfil.dataAlteracaoFormaCobranca = perfil.dataAlteracaoFormaCobranca || new Date()

            const newValue = { ...perfil }
            const oldValue = this.oldPessoaPerfis.find(i => i.id === perfil.id)
            const editarPerfil = this.getModifiedValues(oldValue, newValue)
            const formDataTransportador = this.toFormData(editarPerfil)
            if (!formDataTransportador.has('idTipoPerfil')) formDataTransportador.append('idTipoPerfil', perfil.idTipoPerfil)

            const hasArchives = Object.values(perfil._arquivos).some(value => value !== undefined)
            if (hasArchives) {
              for (const key in perfil._arquivos) {
                let value = typeof perfil._arquivos[key] === 'number' ? String(perfil._arquivos[key]) : perfil._arquivos[key]
                if (value instanceof File) {
                  formDataTransportador.append(key, value)
                  continue
                } else if (value && typeof value === 'object') {
                  value = JSON.stringify(value)
                }
                if (typeof value !== 'undefined') {
                  formDataTransportador.append(key, value)
                }
              }
            }
            if (hasArchives || Object.keys(editarPerfil).length >= 1) await this.$http.put(this.$http.normalizeUrl(`/perfil/update/${perfil.id}`), formDataTransportador)
            continue
          }

          const newValue = { ...perfil }
          if (newValue.capacidadeMaximaSuportada) newValue.capacidadeMaximaSuportada = newValue.capacidadeMaximaSuportada.toFixed(2)

          const oldValue = this.oldPessoaPerfis.find(i => i.id === perfil.id)
          const editarPerfil = this.getModifiedValues(oldValue, newValue)
          if (Object.entries(editarPerfil).length >= 1) {
            const formData = this.toFormData(editarPerfil)
            if (!formData.has('idTipoPerfil')) formData.append('idTipoPerfil', perfil.idTipoPerfil)
            await this.$http.put(this.$http.normalizeUrl(`/perfil/update/${perfil.id}`), formData)
          }
        }
      }
    },
    async perfilRemove () {
      for (const perfil of this.form.perfis) {
        if (perfil._status === 'remove' && perfil.id) {
          if (this.form.tipoPessoaStr === 'F') {
            await this.$http.put(this.$http.normalizeUrl(`/usuario/update/${this.form.usuarios[0].id}`), {
              perfis: [{
                id: perfil.id,
                action: 'delete'
              }]
            })
          }
          await this.$http.delete(this.$http.normalizeUrl(`/Perfil/${perfil.id}`))
        }
      }
    },
    async salvar () {
      /* this.form.perfis.forEach(perfil => {
        // Se tipo perfil for Secretaria ou Fiscalização
        const isSecretariaOrFiscalizacaoOrAdministrador = perfil.idTipoPerfil === 4 || perfil.idTipoPerfil === 5 || perfil.idTipoPerfil === 10
        if (!isSecretariaOrFiscalizacaoOrAdministrador) delete perfil.matricula
        // Se tipo perfil for Transportador do tipo Pessoa Jurídica ou Transportador do tipo Pessoa Física
        const isTransportador = perfil.idTipoPerfil === 2 || perfil.idTipoPerfil === 9
        if (!isTransportador) {
          delete perfil.formaCobranca
        } else {
          perfil.dataAlteracaoFormaCobranca = new Date()
        }
      }) */
      try {
        // if (!this.perfisListComp.length) {
        //   throw new CustomError('Por favor selecione pelo menos um perfil')
        // }
        // if ((this.email && this.login) && this.form.tipoPessoaStr === 'J') {
        //   const usuario = await this.salvarPopupUsuario()
        //   if (usuario.status === false) {
        //     // this.$utils.notifyError(error)
        //     throw new CustomError('Usuário: ' + usuario.msg)
        //   }
        // }
        const pessoaPj = this.form.tipoPessoaStr === 'J' && this.$refs.formularioPessoaPj
          ? {
            ...this.$refs.formularioPessoaPj.getDadosFormulario(),
            cnpj: this.form.cnpj,
            nomeRazaoSocial: this.form.nomeRazaoSocial
          }
          : null

        const usuarioPessoaFisica = this.form.tipoPessoaStr === 'F' && this.$refs.formularioUsuarioPessoaFisica
          ? this.$refs.formularioUsuarioPessoaFisica.getDadosFormulario(this.form.tipoPessoaStr)
          : null

        // if (!this.form.perfis.length) {
        //   throw new CustomError('É necessário adicionar um ou mais perfis')
        // }

        const payload = {
          pessoaPj, // dados da empresa
          perfisDaPessoa: this.form.perfis.map((perfil) => {
            let _status
            if (perfil._status !== 'remove' && this.$utils.isFinalId(perfil.id)) {
              _status = 'update'
            }
            return {
              ...perfil,
              ...(_status ? { _status } : {})
            }
          }),
          // responsaveis: this.form.responsaveis,
          // usuariosPj: this.form.usuariosVinculadoPj,
          usuarioPessoaFisica,
          tipoPessoa: this.form.tipoPessoaStr // F ou J
        }
        const pessoaPjModifiedValues = this.getModifiedValues(this.form.pessoaJuridica, payload.pessoaPj)
        const usuarioPessoaFisicaModifiedValues2 = this.getModifiedValues(this.form.usuarioPessoaFisica, payload.usuarioPessoaFisica)
        const res = payload.tipoPessoa === 'F' ? usuarioPessoaFisicaModifiedValues2 : pessoaPjModifiedValues
        let result
        if (Object.keys(res).length) {
          result = await this.$http.put(this.$http.normalizeUrl(`/Pessoa/${this.id}`), res)
        }
        await this.perfilUpdate()
        await this.perfilRemove()
        await this.perfilSalvar()
        if (payload.tipoPessoa === 'F' && this.form.usuarios.length >= 1) {
          await this.$http.put(this.$http.normalizeUrl(`/usuario/update/${this.form.usuarios[0].id}`), res)
        }
        this.$emit(this.events.save, result)
      } catch (error) {
        await this.$router.push('/pessoa')
      }
    },
    async downloadAnexo (entity, id, attribute) {
      const { data: file } = await http.get(`${baseUrl}/Arquivo/${id}`)
      const fileBuffer = new Uint8Array(file.data.anexo.data)
      const blob = new Blob([fileBuffer], { type: file.data.contentType })
      const fileName = file.data.nomeArquivo
      const link = document.createElement('a')
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    getPerfisSelecionaveis () {
      const perfil = this.form.perfis.filter(perfis => perfis._status !== 'remove')
      return perfil.map((perfil) => {
        perfil._descricao = (this.dynamicValues.tipoPerfilList.find(({ id }) => id === perfil.idTipoPerfil) || {}).descricao
        return perfil
      })
    },
    fecharPopupResponsavel () {
      this.$refs.formPopupResponsavel.startOrReset()
      this.popupResponsavel = { show: false, responsavelToEdit: undefined }
    },
    fecharPopupUsuario () {
      this.$refs.formPopupUsuario.startOrReset()
      this.popupAddUsuario = { show: false, usuarioToEdit: undefined }
    },
    async salvarPopupResponsavel () {
      try {
        const formPopupResponsavel = this.$refs.formPopupResponsavel
        const responsavel = formPopupResponsavel.getDadosFormulario()

        if (this.$utils.isFinalId(responsavel.id)) {
          responsavel._status = 'update'
        } else {
          // validar usuário sem considerar os campos ocultos
          formPopupResponsavel.validarUsuario(false)
          responsavel._status = 'insert'
        }

        if (responsavel.id) {
          const responsaveis = []
          for (const r of this.form.responsaveis) {
            if (r.id === responsavel.id) {
              try {
                const modifiedValues = this.getModifiedValues(r, responsavel)
                this.setLoading(true)
                const result = await this.$http.put(this.$http.normalizeUrl(`/Responsavel/${r.id}`), modifiedValues)
                if (result) {
                  this.$utils.notifySuccess('Responsável atualizado com sucesso')
                  responsaveis.push(responsavel)
                }
                this.setLoading(false)
              } catch (error) {
                this.setLoading(false)
                this.$utils.notifyError(error)
              }
            } else {
              responsaveis.push(r)
            }
          }
          this.form.responsaveis = responsaveis
        } else {
          try {
            this.setLoading(true)
            const result = await this.$http.post(this.$http.normalizeUrl('/Responsavel'), {
              ...responsavel,
              idPessoa: this.id
            })
            if (result) {
              this.setLoading(false)
              this.$utils.notifySuccess('Responsável cadastrado com sucesso')
              responsavel.id = result.data.data.id
              this.form.responsaveis.push(responsavel)
            }
          } catch (error) {
            this.setLoading(false)
            this.$utils.notifyError(error)
          }
        }
        this.fecharPopupResponsavel()
      } catch (error) {
        this.$utils.notifyError(error)
      }
    },
    async salvarPopupUsuario () {
      try {
        let formPopupUsuario = ''
        /**  validação quando se faz o cadastro pelo auto cadasto(sem login)
         * se juridica testa tipo de insert para auto ou nao
         *  */
        if (this.form.tipoPessoaStr === 'J') {
          if (this.email && this.login) {
            formPopupUsuario = this.$refs.formPopupUsuarioAuto
          } else {
            formPopupUsuario = this.$refs.formPopupUsuario
          }
        } else {
          formPopupUsuario = this.$refs.formularioUsuarioPessoaFisica
        }
        const usuario = formPopupUsuario.getDadosFormulario()

        // validar usuário sem considerar os campos ocultos
        // formPopupUsuario.validarUsuario(true)

        if (formPopupUsuario.$props.perfisSelecionaveis && formPopupUsuario.$props.perfisSelecionaveis.length) {
          if (!usuario.idsTipoDePerfil) {
            throw new CustomError('A seleção mínima de um perfil é obrigatória')
          }
          const setOfPerfisByIdTipoPerfil = {}
          for (const perfil of this.form.perfis) {
            setOfPerfisByIdTipoPerfil[perfil.idTipoPerfil] = perfil
          }
          usuario.perfis = usuario.idsTipoDePerfil.map((idTipoPerfil) => setOfPerfisByIdTipoPerfil[idTipoPerfil])
        }

        if (this.$utils.isFinalId(usuario.id)) {
          usuario._status = 'update'
        } else {
          formPopupUsuario.validarUsuario(true)
          usuario._status = 'insert'
        }

        if (usuario.id) {
          const usuarios = []
          for (const u of this.form.usuariosVinculadoPj) {
            if (u.id === usuario.id) {
              const oldValues = u
              const newValues = usuario
              try {
                this.setLoading(true)
                await this.perfilSalvar()
                const modifiedValues = this.getModifiedValues(oldValues, newValues)
                delete modifiedValues.perfis
                const oldValuesIdPerfis = u.perfis.map(item => item.id)
                const newValuesIdPerfis = usuario.perfis.map(item => item.id)
                const perfisDelete = oldValuesIdPerfis.filter(id => !newValuesIdPerfis.includes(id))
                const perfis = []
                perfisDelete.forEach(p => {
                  perfis.push({
                    id: p,
                    action: 'delete'
                  })
                })
                const perfisCreate = newValuesIdPerfis.filter(id => !oldValuesIdPerfis.includes(id))
                perfisCreate.forEach(p => {
                  perfis.push({
                    id: p,
                    action: 'create'
                  })
                })
                if (perfis.length) {
                  modifiedValues.perfis = perfis
                }
                this.setLoading(true)
                await this.perfilSalvar()
                const result = await this.$http.put(this.$http.normalizeUrl(`/usuario/update/${u.id}`), modifiedValues)
                if (result) {
                  this.$utils.notifySuccess('Usuário atualizado com sucesso')
                  usuarios.push(usuario)
                }
                this.setLoading(false)
              } catch (e) {
                this.setLoading(false)
                this.$utils.notifyError(e)
              }
            } else {
              usuarios.push(u)
            }
          }
          this.form.usuariosVinculadoPj = usuarios
        } else {
          try {
            const chavesFiltradas = ['cargo', 'confirmarEmail', 'confirmarSenha', 'idAuth', 'login', 'idsTipoDePerfil', 'perfis', 'profissao', 'rg', 'senha', 'tipoResponsavel']
            const newUsuario = Object.fromEntries(Object.entries(usuario).filter(([chave, valor]) => !chavesFiltradas.includes(chave)))
            newUsuario.perfis = usuario.perfis.map(item => item.id)

            const result = await this.$http.post(this.$http.normalizeUrl('usuario/add'), {
              ...newUsuario,
              idPessoa: this.id
            })
            if (result) {
              this.setLoading(false)
              this.$utils.notifySuccess('Usuário cadastrado com sucesso')
              usuario.id = result.data.user.id
              this.form.usuariosVinculadoPj.push(usuario)
            }
          } catch (e) {
            this.setLoading(false)
            this.$utils.notifyError(e)
          }
        }
        this.fecharPopupUsuario()
        return true
      } catch (error) {
        this.$utils.notifyError(error)
        const user = {
          status: false,
          msg: error
        }
        return user
      }
    },
    mostrarAnexoLicAmbientalComp (perfil) {
      return (this.isTipoPerfil(perfil, 'Gerador') &&
        this.isTipoPerfil(perfil, 'Destino Final') &&
        ['ATT', 'Aterro', 'Recicladora'].includes(perfil.tipoDestinoFinal))
    },
    getTipoPerfilDisponivelList (perfil) {
      const tipoDePerfilSelecionadoIds = this.perfisListComp.map(({ idTipoPerfil }) => idTipoPerfil)
      return this.dynamicValues.tipoPerfilList.filter((tipoPerfil) => {
        return perfil.idTipoPerfil === tipoPerfil.id || !tipoDePerfilSelecionadoIds.includes(tipoPerfil.id)
      })
    },
    async removerUsuario (usuario) {
      const usuariosVinculadoPj = []
      for (const u of this.form.usuariosVinculadoPj) {
        if (u.id === usuario.id) {
          try {
            this.setLoading(true)
            const result = await this.$http.put(this.$http.normalizeUrl(`/Usuario/${u.id}`), {
              excluido: 'S'
            })
            if (result) {
              this.$utils.notifySuccess('Usuário removido com sucesso')
              usuariosVinculadoPj.push({
                ...usuario,
                _status: 'remove'
              })
            }
            this.setLoading(false)
          } catch (e) {
            this.setLoading(false)
            this.$utils.notifyError(e)
          }
        } else {
          usuariosVinculadoPj.push(u)
        }
      }
      this.form = {
        ...this.form,
        usuariosVinculadoPj
      }
    },
    async removerResponsavel (responsavel) {
      try {
        const responsaveis = []
        for (const r of this.form.responsaveis) {
          if (r.id === responsavel.id) {
            this.setLoading(true)
            const result = await this.$http.delete(this.$http.normalizeUrl(`/Responsavel/${r.id}`))
            if (result) {
              this.$utils.notifySuccess('Responsável removido com sucesso')
              responsaveis.push({
                ...responsavel,
                _status: 'remove'
              })
            }
            this.setLoading(false)
            this.form = {
              ...this.form,
              responsaveis
            }
          } else {
            responsaveis.push(r)
            this.form = {
              ...this.form,
              responsaveis
            }
          }
        }
      } catch (e) {
        this.setLoading(false)
        this.$utils.notifyError(e)
      }
    },
    addPerfil () {
      this.form.perfis.unshift({
        _status: 'insert',
        _arquivos: {}
      })
    },
    removePerfil (perfil) {
      try {
        const usuariosPjVinculadosPerfilRemovido = []
        for (const usuarioPj of (this.form.usuariosVinculadoPj || [])) {
          if (usuarioPj._status !== 'remove' && usuarioPj.idsTipoDePerfil.includes(perfil.idTipoPerfil)) {
            usuariosPjVinculadosPerfilRemovido.push(usuarioPj)
          }
        }
        if (usuariosPjVinculadosPerfilRemovido.length) {
          throw new CustomError(`Não foi possível remover este perfil, pois ele está atribuíto ao(s) usuário(s): ${usuariosPjVinculadosPerfilRemovido.map(({ nome }) => nome).join(', ')}!`)
        }
        perfil._status = 'remove'
      } catch (error) {
        this.$utils.notifyError(error, 'Não foi possível remover este perfil', 5000)
      }
    },
    isTipoPerfil (perfil, tipoAVerificar = null) {
      const tipoPerfil = this.dynamicValues.tipoPerfilList.find(({ id }) => id === perfil.idTipoPerfil)
      return (tipoPerfil || {}).descricao === tipoAVerificar
    },
    putDynamicValue (key, value) {
      this.dynamicValues = {
        ...this.dynamicValues,
        [key]: value
      }
    },
    calculateDataAlteracaoFormaCobranca (dateString) {
      const datePlus90Days = moment(new Date(dateString)).add(90, 'days')
      if (moment().valueOf() <= datePlus90Days.valueOf()) {
        return {
          isValid: false,
          message: `Atenção, o tipo de cobrança só poderá ser alterado em ${moment().locale('pt-br').to(datePlus90Days, true)}`
        }
      } else {
        return {
          isValid: true,
          message: ''
        }
      }
    },
    toggleDefault (index) {
      if (index && this.$refs[`tooltip-${index}`]) {
        this.$refs[`tooltip-${index}`][0].instance.toggle()
      }
    },
    toggleUploader (index) {
      if (index && this.$refs[`tooltip-uploader-${index}`] && Array.isArray(this.$refs[`tooltip-uploader-${index}`]) && this.$refs[`tooltip-uploader-${index}`].length) {
        this.$refs[`tooltip-uploader-${index}`][0].instance.toggle()
      }
    }
  }
}
</script>
