<template>
  <div>
    <FormPessoaUpdate
      :id="id"
      @save="onSave"
    />
  </div>
</template>
<script>
import FormPessoaUpdate from '@/pages/Cruds/Pessoa/Form/FormPessoaUpdate.vue'
export default {
  props: {
    id: {
      type: [Number, String]
    }
  },
  watch: {
    showModal (showModal) {
      this.$emit('update:modal', showModal)
    }
  },
  components: { FormPessoaUpdate },
  methods: {
    async onSave (response = {}) {
      try {
        let message = null
        let result = null
        result = response.data
        message = 'Edições feitas com sucesso'
        this.$utils.notifySuccess(message)
        this.$emit('save-sucess', result)
        if (!this.email) await this.$router.push('/pessoa')
      } catch (error) {
        console.error('error', error)
      }
    }
  }
}
</script>
